<template>
	<b-modal
		id="modal-schedule"
		size="xl"
		title="Horarios del Doctor"
		no-close-on-backdrop
		hide-footer
	>
		<b-form @submit.stop.prevent="updateSchedule">
			<b-row v-for="(value, idx) in attentionSchedule" :key="idx" class="mb-2">
				<b-col md="2" style="margin: auto">
					<label>{{ value.day }}:</label> <br />
					<b-form-checkbox
						v-model="value.attention"
						:value="true"
						:unchecked-value="false"
						@change="toggleAttention(idx)"
					>
						{{ value.attention ? "Hay Atención" : "No Hay Atención" }}
					</b-form-checkbox>
				</b-col>
				<b-col md="10">
					<b-row v-for="(hour, index) in value.times" :key="index" class="mb-2">
						<b-col md="5">
							<input
								class="form-control"
								type="time"
								v-model="attentionSchedule[idx].times[index].start_time"
								:disabled="!value.attention"
							/>
						</b-col>
						<b-col md="5">
							<input
								class="form-control"
								type="time"
								v-model="attentionSchedule[idx].times[index].final_time"
								:disabled="!value.attention"
							/>
						</b-col>
						<b-col md="2" style="margin: auto">
							<b-button
								v-if="
									attentionSchedule[idx].times.length >= 1 &&
									index === attentionSchedule[idx].times.length - 1
								"
								variant="primary"
								@click="addTime(idx)"
							>
								<i class="fa fa-plus"></i>
							</b-button>
							<b-button v-else variant="danger" @click="deleteTime(idx, index)">
								<i class="fa fa-minus"></i>
							</b-button>
						</b-col>
					</b-row>
				</b-col>
				<b-col md="12">
					<hr />
				</b-col>
			</b-row>
			<b-button v-user-can="'actualizar configuracion'" type="submit" variant="primary">
				Actualizar
			</b-button>
		</b-form>
	</b-modal>
</template>

<script>
import { Toast } from "../../../mixins/toast";
import api from "../../../services/api";

export default {
	props: {
		id: {
			type: Number,
			required: true,
		},
		data: {
			type: Array,
			required: false,
		},
	},
	mounted() {},
	data() {
		return {
			attentionSchedule: [],
			originalSchedule: [
				{
					day: "Lunes",
					attention: true,
					times: [{ start_time: "08:00:00", final_time: "18:00:00" }],
				},
				{
					day: "Martes",
					attention: true,
					times: [{ start_time: "08:00:00", final_time: "18:00:00" }],
				},
				{
					day: "Miércoles",
					attention: true,
					times: [{ start_time: "08:00:00", final_time: "18:00:00" }],
				},
				{
					day: "Jueves",
					attention: true,
					times: [{ start_time: "08:00:00", final_time: "18:00:00" }],
				},
				{
					day: "Viernes",
					attention: true,
					times: [{ start_time: "08:00:00", final_time: "18:00:00" }],
				},
				{
					day: "Sábado",
					attention: false,
					times: [{ start_time: "08:00:00", final_time: "20:00:00" }],
				},
				{
					day: "Domingo",
					attention: false,
					times: [{ start_time: "08:00:00", final_time: "20:00:00" }],
				},
			],
			doctorSelectedId: 1,
		};
	},
	watch: {
		id: function (newValue, oldValue) {
			this.doctorSelectedId = newValue;
		},
		data: function (newValue, oldValue) {
			if (newValue.length > 0) {
				this.attentionSchedule = newValue;
			} else {
				this.attentionSchedule = JSON.parse(JSON.stringify(this.originalSchedule));
			}
		},
	},
	methods: {
		toggleAttention(index) {
			this.attentionSchedule[index].times = [
				{
					start_time: "08:00:00",
					final_time: "20:00:00",
				},
			];
		},
		addTime(idx) {
			this.attentionSchedule[idx].times.push({
				start_time: "08:00:00",
				final_time: "20:00:00",
			});
		},
		deleteTime(idx, index) {
			this.attentionSchedule[idx].times.splice(index, 1);
		},
		validateTimes(times) {
			for (let i = 0; i < times.length; i++) {
				const { start_time, final_time } = times[i];

				// Validar que los start_time no sean mayores que los final_time
				if (start_time > final_time) {
					return false;
				}

				// Asegurarse de que los start_time a partir del segundo objeto no sean menores al final_time del objeto anterior
				if (i > 0) {
					const previousFinalTime = times[i - 1].final_time;
					if (start_time < previousFinalTime) {
						return false;
					}
				}
			}

			return true;
		},
		validateData(data) {
			for (const item of data) {
				if (item.attention) {
					const isValid = this.validateTimes(item.times);
					if (!isValid) {
						return false;
					}
				}
			}

			return true;
		},
		updateSchedule() {
			const isValid = this.validateData(this.attentionSchedule);
			if (isValid) {
				api
					.post(`/doctors/${this.doctorSelectedId}/schedules`, this.attentionSchedule)
					.then(() => {
						Toast.fire({
							icon: "success",
							title: "Horarios Actualizados",
						});
						this.$bvModal.hide("modal-schedule");
					})
					.catch((error) => {
						const errorMessage =
							error.response?.data?.message || "Error al guardar los horarios";
						Toast.fire({
							icon: "error",
							title: errorMessage,
						});
					});
			} else {
				Toast.fire({
					icon: "error",
					title: "Horario no es válido.",
				});
			}
		},
	},
};
</script>
