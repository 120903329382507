<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Lista de Personal de Salud</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-user-can="'crear doctores'" variant="primary" @click="showModal('add', clearForm)">
              Agregar
            </b-button>
          </template>
          <template v-slot:body>
            <DataTable v-if="doctors" :data="doctors" :columns="columns" :fields-search="[
              'first_last_name',
              'second_last_name',
              'name',
              'email',
              'document_number',
            ]">
              <template #rows="{ row, rowIndex }">
                <tr>
                  <th scope="row">{{ ++rowIndex }}</th>
                  <td>
                    {{ row.first_last_name }}
                    {{ row.second_last_name }}, {{ row.name }} <br />
                    <small><b>Edad:</b> {{ row.age }} años</small> |
                    <small><b>Género:</b> {{ row.gender }}</small>
                  </td>
                  <td>
                    {{ row.document_number }} <br />
                    <small><b>Tipo:</b> {{ row.document_type }}</small>
                  </td>
                  <td>{{ row.phone }}</td>
                  <td>{{ row.email }}</td>
                  <td>
                    <b-button-group>
                      <!-- <b-button
                        v-user-can="'leer doctores'"
                        variant="secondary"
                        size="sm"
                        @click="
                          $router.push({
                            name: 'doctor.detail',
                            params: { id: row.id },
                          })
                        "
                        v-b-tooltip.hover
                        title="Detalle"
                      >
                        <i class="ri-file-list-line m-0"></i>
                      </b-button> -->
                      <b-button v-user-can="'leer doctores'" variant="success" size="sm" @click="showModalSchedule(row)"
                        v-b-tooltip.hover title="Horarios">
                        <i class="ri-calendar-line m-0"></i>
                      </b-button>
                      <b-button v-user-can="'actualizar doctores'" variant="warning" size="sm"
                        @click="showModal('edit', row)" v-b-tooltip.hover title="Editar">
                        <i class="ri-ball-pen-fill m-0"></i>
                      </b-button>
                      <b-button v-user-can="'eliminar doctores'" variant="danger" size="sm" @click="destroy(row.id)"
                        v-b-tooltip.hover title="Eliminar">
                        <i class="ri-delete-bin-line m-0"></i>
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </template>
            </DataTable>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <ModalForm @getData="getData" :type="type" :data="data" />
    <ModalSchedule :data="schedules" :id="doctorSelectedId" />
  </b-container>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import { xray } from '../../../config/pluginInit'
import api from './../../../services/api'
import DataTable from './../../../components/DataTable.vue'
import ModalForm from './ModalForm.vue'
import ModalSchedule from './ModalSchedule.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    ModalForm,
    ModalSchedule,
    DataTable
  },
  mounted () {
    xray.index()
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const { data } = await api.get(`/doctors`)
        this.doctors = data.data
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    showModal (type, data) {
      this.type = type
      this.data = data
      this.$bvModal.show('modal-form')
    },
    showModalSchedule (data) {
      this.schedules = data.schedules
      this.doctorSelectedId = data.id
      this.$bvModal.show('modal-schedule')
    },
    destroy (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Seguro de eliminar',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/doctors/${id}`)
            .then((res) => {
              if (res.status === 200) {
                Toast.fire({
                  icon: 'success',
                  title: 'Eliminado correctamente'
                })
                this.getData()
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: 'error',
                title: error.message
              })
            })
        }
      })
    }
  },
  data () {
    return {
      data: {},
      type: 'add',
      doctors: [],
      columns: [
        {
          name: 'Nombre',
          field: 'first_last_name',
          sort: ''
        },
        {
          name: 'Documento',
          field: 'document_number',
          sort: ''
        },
        {
          name: 'Teléfono',
          field: 'phone',
          sort: ''
        },
        {
          name: 'Correo Electrónico',
          field: 'email',
          sort: ''
        },
        {
          name: 'Acciones',
          field: 'actions',
          sort: ''
        }
      ],
      clearForm: {
        first_last_name: '',
        second_last_name: '',
        name: '',
        document_type: '',
        document_number: '',
        email: '',
        password: '',
        civil_status: '',
        birthdate: '',
        gender: '',
        province: '',
        city: '',
        phone: '',
        address: '',
        degree: '',
        years_experience: ''
      },
      schedules: [],
      doctorSelectedId: 1
    }
  }
}
</script>
